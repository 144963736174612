import { useState, useEffect, useRef } from 'react';
import googleOAuth2 from 'js-utils/google-oauth2';
import { User } from 'src/types/global';
import ConfirmDeleteChatsModal from './ConfirmDeleteChatsModal';
import { useMessageStore } from 'src/store';
import { deleteAllChats } from 'src/services/delete';
import config from 'src/config';
import { useTheme } from 'src/context/ThemeContext';

interface UserAvatarProps {
	currentUser: User;
	logout: () => void;
	clearAllChats: () => void;
}

const UserAvatar = ({
	currentUser,
	logout,
	clearAllChats,
}: UserAvatarProps) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isDeleteChatsModalOpen, setIsDeleteChatsModalOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const { isDarkMode, toggleTheme } = useTheme();

	const showMessage = useMessageStore((state) => state.setMessage);

	const LOGOUT_URL = config.VERSION_ENDPOINT + 'auth/logout';

	const handleLogout = async () => {
		try {
			await googleOAuth2.logout(LOGOUT_URL);
			logout();
		} catch (error) {
			console.log('Error occurred during logging out', error);
		}
	};

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsDropdownOpen(false);
		}
	};

	const handleDeleteChats = async () => {
		try {
			await deleteAllChats();
			clearAllChats();
			showMessage({
				message: 'All chats have been deleted',
				visible: true,
				type: 'success',
			});
		} catch (error) {
			showMessage({
				message: 'Error occurred while deleting chats',
				visible: true,
				type: 'error',
			});
		}
		setIsDeleteChatsModalOpen(false);
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="user-avatar-container" ref={dropdownRef}>
			<button className="avatar-button" onClick={toggleDropdown}>
				<img
					src={currentUser.picture}
					alt="User Avatar"
					className="avatar-image"
				/>
			</button>
			{isDropdownOpen && (
				<div className="dropdown-container">
					<div className="dropdown-menu">
						<button
							className="dropdown-item"
							onClick={toggleTheme}
						>
							{isDarkMode ? '☀️ Light Mode' : '🌙 Dark Mode'}
						</button>
					</div>

					<div className="dropdown-menu">
						<button
							className="dropdown-item"
							onClick={() => setIsDeleteChatsModalOpen(true)}
						>
							Delete all chats
						</button>
					</div>

					<div className="dropdown-menu">
						<button className="dropdown-item" onClick={handleLogout}>
							Logout
						</button>
					</div>
				</div>
			)}
			<ConfirmDeleteChatsModal
				isOpen={isDeleteChatsModalOpen}
				onCancel={() => setIsDeleteChatsModalOpen(false)}
				onConfirm={handleDeleteChats}
			/>
		</div>
	);
};

export default UserAvatar;
