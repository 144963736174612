import { RefObject, useCallback, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useState } from 'react';
import CodeBlock from './codeBlock/CodeBlock';

interface BotResponseProps {
	response: string;
	chatLogRef?: RefObject<HTMLDivElement>;
	hasPrinted?: boolean;
	setHasPrinted?: (hasPrinted: boolean) => void;
	sources?: any;
	isLast: boolean;
	isPrinting: boolean;
	setIsPrinting: (isPrinting: boolean) => void;
}

const BotResponse = ({
	response,
	chatLogRef,
	sources,
	hasPrinted,
	setHasPrinted,
	isLast,
	isPrinting,
	setIsPrinting,
}: BotResponseProps) => {
	const [botResponse, setBotResponse] = useState('');
	const [displaySources, setDisplaySources] = useState<JSX.Element | null>(
		null,
	);
	const [isAutoScrolling, setIsAutoScrolling] = useState(false);
	const [isFirstPrint, setIsFirstPrint] = useState(true);

	// Render sources as hyperlinks
	const renderSources = useCallback(() => {
		if (sources == null || sources.length === 0) {
			return null;
		}

		return (
			<>
				<div style={{ marginTop: '20px' }}>Sources:</div>
				{sources.map((item, index) => (
					<div key={index} id="statusMessage" ref={chatLogRef}>
						<ul>
							<li>
								{item.source ? (
									<a
										href={item.source}
										target="_blank"
										rel="noopener noreferrer"
									>
										{item.title}
									</a>
								) : (
									// Don't show as clickable URL for null sources
									<p>{item.title}</p>
								)}
							</li>
						</ul>
					</div>
				))}
			</>
		);
	}, [chatLogRef, sources]);

	useEffect(() => {
		let index: number = 1;
		if (hasPrinted) {
			setDisplaySources(renderSources());
			setBotResponse(response);
			setIsPrinting(false);
			return;
		}

		if (!isLast) {
			return;
		}

		if (isFirstPrint) {
			setIsFirstPrint(false);
			setIsAutoScrolling(true);
			setIsPrinting(true);
		}
		setIsAutoScrolling(true);
		const msg = setInterval(() => {
			if (!isPrinting) {
				// if isPrinting is false, clear interval and return
				setBotResponse(response);
				setDisplaySources(renderSources());
				clearInterval(msg);
				return;
			}

			setBotResponse(response.slice(0, index));
			if (index >= response.length) {
				clearInterval(msg);
				setIsPrinting(false);
				setHasPrinted && setHasPrinted(true); // Set hasPrinted to true after printing is complete
			}
			index = index + 10;

			if (chatLogRef !== undefined && isAutoScrolling) {
				chatLogRef.current?.scrollIntoView({
					behavior: 'auto',
					block: 'end',
				});
			}
		}, 50);

		setDisplaySources(renderSources());
		return () => clearInterval(msg); // clear interval on component unmount
	}, [
		chatLogRef,
		response,
		isPrinting,
		renderSources,
		hasPrinted,
		setHasPrinted,
		isLast,
		setIsPrinting,
		isFirstPrint,
		isAutoScrolling,
	]);

	return (
		<div className="botMessage">
			<ReactMarkdown
				components={{
					code: CodeBlock,
					a: ({ node, children, ...props }) => (
						<a {...props} target="_blank" rel="noopener noreferrer">
							{children && children.length > 0 ? children : props.href}
						</a>
					),
				}}
			>
				{botResponse}
			</ReactMarkdown>
			{displaySources !== null && displaySources}
		</div>
	);
};

export default BotResponse;
