import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { ThemeProvider as FabricThemeProvider } from '@bamboohr/fabric';

type ThemeContextType = {
  isDarkMode: boolean;
  toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  // Check if dark mode is stored in localStorage, default to false
  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    const savedTheme = localStorage.getItem('darkMode');
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  // Toggle between light and dark mode
  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // Update localStorage and apply CSS classes when theme changes
  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    
    // Apply or remove dark-mode class on the body element
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
      document.body.classList.add('bamboo-dark-theme');
      // Apply a custom data attribute for dark mode styling
      document.body.setAttribute('data-theme', 'dark');
      // Set the Fabric UI theme
      document.body.setAttribute('data-fabric-theme', 'encore');
    } else {
      document.body.classList.remove('dark-mode');
      document.body.classList.remove('bamboo-dark-theme');
      document.body.setAttribute('data-theme', 'light');
      // Set the Fabric UI theme
      document.body.setAttribute('data-fabric-theme', 'encore');
    }
  }, [isDarkMode]);

  // Apply the initial theme on component mount
  useEffect(() => {
    // Check if dark mode is stored in localStorage
    const savedTheme = localStorage.getItem('darkMode');
    const initialDarkMode = savedTheme ? JSON.parse(savedTheme) : false;
    
    // Apply the initial theme
    if (initialDarkMode) {
      document.body.classList.add('dark-mode');
      setIsDarkMode(true);
    } else {
      document.body.classList.remove('dark-mode');
      setIsDarkMode(false);
    }
  }, []);

  const value = {
    isDarkMode,
    toggleTheme,
  };

  // Use the Fabric ThemeProvider with the encore theme
  return (
    <ThemeContext.Provider value={value}>
      <FabricThemeProvider themeName="encore">
        {children}
      </FabricThemeProvider>
    </ThemeContext.Provider>
  );
};

// Custom hook to use the theme context
export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export default ThemeProvider;
